export const ICONS = {
	success: "icon-success",
	failed: "icon-failed",
	timer: "icon-timer",
	cost: "icon-cost",
	sports: "icon-sports",
	sport: "icon-sport",
	calender: "icon-calender",
	noData: "icon-noData",
	offDays: "icon-offDays",
	mailSuccess: "icon-mailSuccess",
	mail: "icon-mail",
	mailError: "icon-mailError",
	profile: "profile",
	trophy: "pi-trophy",
	check: "icon-check",
	checkWhite: "icon-check-color-white",
	notSelected_check: "icon-notSelected_check",
	celebration: "icon-celebration",
	google: "google",
	apple: "apple",
	facebookWhite: "facebook-white",
	warning: "warning",
	whatsapp: "whatsapp",
	users: "users",
	cup: "cup",
	stadium: "stadium",
	discount:'discount'
};

export type IconsType = (typeof ICONS)[keyof typeof ICONS];
